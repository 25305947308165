<template>
  <div style="position:relative">
    <div class="manage" @click="manageFlag=!manageFlag"><i style="font-size:1.6rem" class="el-icon-setting"></i></div>
    <Header :title="'选择收款账户'" :back="true"/>
    <div class="box-items bank flex-box" v-for="item in banksList" :key="item.id">
      <div class="manage-box" v-if="manageFlag">
          <span class="theme-color" @click="removeBank(item.id,item.tailnumber)">删除</span>
        </div>
      <!-- <div class="bank-icon" @click="banksComfirm(item.id)"><img :src="require('@/assets/img/1.png')"/></div> -->
      <div v-if="item.type=='hongkong'" @click="banksComfirm(item.id)">
        <div class="theme-color">{{item.enbank}}(尾号{{item.tailnumber}})</div>
        <div class="sub">{{item.bank}}，代码{{item.bankcode}}</div>
      </div>
      <div v-else @click="banksComfirm(item.id)">
        <div class="theme-color">{{item.bank}}(尾号{{item.tailnumber}})</div>
        <div class="sub">{{item.address}}，SWIFT码{{item.swift}}</div>
      </div>
    </div>
    <div class="add-bank" @click="gotoAdd">
      <!-- <div><img :src="require('@/assets/img/1.png')"/></div> -->
      <div class="add-text">添加新收款账户</div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return{
      banksList:[],
      manageFlag:false,
    }

  },
  created(){
    this.getBanksData()
  },
  methods:{
    getBanksData(){
      this.$store.dispatch('transation/getBanks').then(res => {
        if(res&&res.code=='success'){
          this.banksList = res.result
        }
      })
    },
    banksComfirm(id){
      this.$router.push({path:'transferout',query:{id:id}})
    },
    gotoAdd(){
      this.$router.push({path:'addbank'})
    },
    // editBank(id){
    //   this.$router.push({path:'addbank',query:{id:id}})
    // },
    removeBank(id,num){
      this.$confirm('确定要删除尾号'+num+'的银行卡吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
        }).then(() => {
          let params={
            id:id
          }
          this.$store.dispatch('transation/removeBank',{params:params}).then(res => {
            if(res&&res.code=='success'){
              this.getBanksData()
            }
          })
        }).catch(() => {});
      
    }
  }
  
}
</script>
<style lang="scss" scoped>
.manage{
  position: absolute;
  right:11%;
  top:3px;
  z-index: 4;
  line-height: 3rem;
  color: #ffffff;
}

.bank{
  margin-top:2.5%;
  padding: 7% 3%;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  .bank-icon{
    width: 40px;
  }
  .manage-box{
    position: absolute;
    right: 5px;
    top:6px;
    z-index: 2;
    span{
      margin:0px 5px;
      font-size: .9rem;
    }
  }
  .sub{
    color: #464646;
    font-size: .9rem;
    margin-top:.5rem
  }
}
.add-bank{
  border-radius:8px;
  height: 5rem;
  border:1px dashed #002D91;
  margin:2.5%;
  text-align: center;
  padding-top:1rem;
  .add-text{
    line-height: 4rem;
    font-size:1rem;
    color: #464646;
  }
}
</style>