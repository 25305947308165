var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c(
        "div",
        {
          staticClass: "manage",
          on: {
            click: function ($event) {
              _vm.manageFlag = !_vm.manageFlag
            },
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-setting",
            staticStyle: { "font-size": "1.6rem" },
          }),
        ]
      ),
      _c("Header", { attrs: { title: "选择收款账户", back: true } }),
      _vm._l(_vm.banksList, function (item) {
        return _c(
          "div",
          { key: item.id, staticClass: "box-items bank flex-box" },
          [
            _vm.manageFlag
              ? _c("div", { staticClass: "manage-box" }, [
                  _c(
                    "span",
                    {
                      staticClass: "theme-color",
                      on: {
                        click: function ($event) {
                          return _vm.removeBank(item.id, item.tailnumber)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ])
              : _vm._e(),
            item.type == "hongkong"
              ? _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.banksComfirm(item.id)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "theme-color" }, [
                      _vm._v(
                        _vm._s(item.enbank) +
                          "(尾号" +
                          _vm._s(item.tailnumber) +
                          ")"
                      ),
                    ]),
                    _c("div", { staticClass: "sub" }, [
                      _vm._v(
                        _vm._s(item.bank) + "，代码" + _vm._s(item.bankcode)
                      ),
                    ]),
                  ]
                )
              : _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.banksComfirm(item.id)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "theme-color" }, [
                      _vm._v(
                        _vm._s(item.bank) +
                          "(尾号" +
                          _vm._s(item.tailnumber) +
                          ")"
                      ),
                    ]),
                    _c("div", { staticClass: "sub" }, [
                      _vm._v(
                        _vm._s(item.address) + "，SWIFT码" + _vm._s(item.swift)
                      ),
                    ]),
                  ]
                ),
          ]
        )
      }),
      _c("div", { staticClass: "add-bank", on: { click: _vm.gotoAdd } }, [
        _c("div", { staticClass: "add-text" }, [_vm._v("添加新收款账户")]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }